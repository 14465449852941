.osmMapLabel {
  background-color: rgba(255,255,255,0.8);
  border: none;
  box-shadow: none;
  font-size: 12px;
  font-weight: bold;
  padding: 2px;
  opacity: 1;
}

.osmMapLabel:before {
  display: none;
}