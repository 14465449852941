@import "index.scss";

.mapIcon {
  background-color: white;
  border: 3px solid rgba(255, 80, 0, 0.5);
  border-radius: 50%;
  display: flex;
  padding: 2px;
}

.discrete {
  opacity: 0.3;
}

.mapIcon.discrete {
  border: none;
  opacity: 0.3;
}

.mapIcon.discrete:hover {
  opacity: 1;
  border: 1px solid rgba(255, 80, 0, 0.5);
}

.wwPopup {
  .leaflet-popup-content {
    margin: 4px 0px 4px;
  }
}

.mapOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1000;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.7);
}

.wwModalImg {
  width: 100%;
}
